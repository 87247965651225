import * as React from 'react'
import { useAppSelector } from '../../store/reducers/store';
import { Constants } from '../../utils/Constants';

function ChatReferences({ entry, openReferences }) {
    const searchTarget = useAppSelector(state => state.chat.searchTarget);
    const clickable = searchTarget === Constants.SearchTarget.Web || searchTarget === Constants.SearchTarget.Sharepoint || searchTarget === Constants.SearchTarget.GLPI;
    if (entry.references === undefined || entry.references.length === 0) {
        return <></>
    }


    return (
        <>
            {entry.references.map((r, index) => {
                const fileName = r.url !== undefined && r.url !== null ? r.url : r.document;
                var fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
                if(fileExtension.length > 5 &&  searchTarget === "Web"  ) {
                    fileExtension = "web";
                }
               else if(fileExtension.length > 5  && searchTarget === "Email"){
                    fileExtension = "mail";
                }
                else if(fileExtension.length > 5  && searchTarget === Constants.SearchTarget.GLPI){
                    fileExtension = "ticket";
                }


                // if (clickable){
                    return <a href={fileName} target='_blank' className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension }`} key={`reference-${index}`} onClick={openReferences}>[{index +1}] {r.document}</a>
                // }else{
                //     return <span className={`gpt-documents ${entry.deployement === "web" ? "web" : fileName.substring(fileName.lastIndexOf('.') + 1)}`} key={`reference-${index}`}>[{index +1}]{r.document}</span>
                // }
            })}
        </>
    )
}

export default ChatReferences;