import React, { useMemo, useState, useEffect } from "react";
import logosmall from '../.././assets/images/logosmall.svg';
import Settings from './Settings.js';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SideBar from './SideBar.js';
import { useAppDispatch, useAppSelector } from "../../store/reducers/store.js";
import { Constants } from "../../utils/Constants.js";
import { newChat, setGPTVersion } from "../../store/actions/chat.js";
import config from "../../config.json"
import { useMsal } from "@azure/msal-react";

const darkModeStorage = JSON.parse(localStorage.getItem('darkMode')) || false;
let settings; let symbol; let sidebar; let add; let toggleDarkIcon ;
if (darkModeStorage) {
    toggleDarkIcon = require('../.././assets/images/light_icon.svg').default;
    settings = require('../.././assets/images/settings_dark.svg').default;
    symbol = require('../.././assets/images/sharepoint_dark.svg').default;
    sidebar = require('../.././assets/images/sidebar_dark.svg').default;
    add = require('../.././assets/images/addsidebar_dark.svg').default;
} else {
    toggleDarkIcon = require('../.././assets/images/dark_icon.svg').default;
    settings = require('../.././assets/images/settings.svg').default;
    symbol = require('../.././assets/images/sharepoint.svg').default;
    sidebar = require('../.././assets/images/sidebar.svg').default;
    add = require('../.././assets/images/add.svg').default;
}

const NavBar = ({ displaySide, onDisplaySideOpen, onDisplaySideClose }) => {
    const dispatch = useAppDispatch();
    const version = useAppSelector(state => state.chat.gptVersion);
    const [showSettingsBlock, setShowSettingsBlock] = useState(false);
    const [darkMode, setDarkMode] = useState(darkModeStorage);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        window.location.reload();
        localStorage.setItem('darkMode', JSON.stringify(!darkMode));
    };

    // Persisting dark mode changes to localStorage (optional)
    useEffect(() => {
        const storedMode = localStorage.getItem('darkMode');
        if (storedMode) {
            setDarkMode(JSON.parse(storedMode));
        }
    }, []);

    const handleChange = (event) => {
        dispatch(setGPTVersion(event.target.value));
    };

    const OpenSettings = () => {
        setShowSettingsBlock(true);
    };

    const CloseSettings = () => {
        setShowSettingsBlock(false);
    };

    const [showSideBarBlock, setSideBarBloc] = useState(displaySide);

    const AdjustSideBar = () => {
        setSideBarBloc(false);
        onDisplaySideClose();
    };

    const OpenSideBar = () => {
        setSideBarBloc(true);
        onDisplaySideOpen();
    };
    const onNewChat = () => {
        dispatch(newChat({ account: instance.getActiveAccount() }));
    }

    const { instance } = useMsal();
    const initial = useMemo(() => {
        return instance.getActiveAccount().username.charAt(0).toLocaleUpperCase();
    }, [instance])
    const handleLogoutClick = () => {
        //instance.logoutRedirect()
        instance.logoutPopup()
            .then(function (response) { window.location.href = "/" })
            .catch((error) => console.error(error))
    }

    const [isLogOutVisible, setIsLogOutVisible] = useState(false);
    const handleLogoutVisibleClick = () => {
        setIsLogOutVisible(!isLogOutVisible);
    };

    const handleClickOutside = (event) => {
        if (isLogOutVisible && !event.target.closest('.nav-item')) {
            setIsLogOutVisible(false);
        }
    };



    return (
        <>
           
            {config.features.newChat && <SideBar displaySide={showSideBarBlock} onDisplaySideChange={AdjustSideBar} />}
            <Settings display={showSettingsBlock} onDisplayChange={CloseSettings} />
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="row">
                    <div className="col-md-6">
                        <div className="leftNav">
                            <div className="navActions">
                                {config.features.newChat && <a className="nav-action" href="#" id="sideBar" onClick={OpenSideBar} style={{ display: showSideBarBlock ? 'none' : 'inline-block' }} >
                                    <img src={sidebar} alt="Sidebar" />
                                </a>}

                                {config.features.newChat &&
                                    <a className="nav-action" href="javascript:;" onClick={onNewChat} id="main_newChat" style={{ display: showSideBarBlock ? 'none' : 'inline-block' }}>
                                        <img src={add} alt="Add" />
                                    </a>}
                                <img src={logosmall} alt="Logo" />
                                <p> {config.homePage.text.appName} </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="rightNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link darkModeButton" onClick={toggleDarkMode}>
                                        <img src={toggleDarkIcon} alt="Dark Mode Toggle" />
                                    </a>

                                </li>
                            </ul>
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="chat-version"
                                        id="chat-version"
                                        value={version}
                                        label="Version"
                                        onChange={handleChange}
                                        sx={{
                                            '& .MuiSelect-icon': {
                                                color: darkMode ? '#FFFFFF' : '#000000', // White for dark mode, black for light mode
                                            },
                                        }}
                                    >
                                        <MenuItem value={Constants.GPTVersion["4"]}>GPT4</MenuItem>
                                        {/* <MenuItem value={Constants.GPTVersion["3.5"]} >GPT 3.5</MenuItem> */}
                                    </Select>
                                </FormControl>
                            </Box>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href={Constants.Redirections.SharePointLibrary} target="_blank">
                                        <img src={symbol} alt="Symbol" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" onClick={OpenSettings}>
                                        <img src={settings} alt="Settings" />
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#" id="initialButton" onClick={handleLogoutVisibleClick}>
                                        <span className="userCharacter"> {initial} </span>
                                    </a>
                                    {isLogOutVisible && (
                                        <div className="logOut">
                                            <Link to="javascript:;" onClick={handleLogoutClick}> {config.homePage.text.logout} </Link>
                                        </div>
                                    )}
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavBar;
