import React, { useState, useEffect } from "react";
import { useMsal } from '@azure/msal-react';
import { Routes, Route } from 'react-router-dom';
import Login from "./pages/Login.js";
import Disclaimer from './pages/Disclaimer.js';
import Home from './pages/Home.js';
import Chat from './pages/Chat.js';
import Documents from './pages/Documents.js';
import RedirectLoginPage from './pages/redirectLogin.js';
// import './assets/styles/style.css';
import { useAppDispatch } from './store/reducers/store'
import { setFavicon } from "./utils/setFavicons.js";
import config from "./config.json"
import { loginRequest } from "./appConfig.js";
import { setToken } from "./store/actions/chat.js";

const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
  import('./assets/styles/dark.css').then(() => {
    console.log('Dark mode styles loaded');
  }).catch(err => {
    console.error('Failed to load dark mode styles', err);
  });
} else {
  import('./assets/styles/style.css').then(() => {
    console.log('Light mode styles loaded');
  }).catch(err => {
    console.error('Failed to load light mode styles', err);
  });
}

function App() {
  //const { instance } = useMsal();
  // const [accessToken, setAccessToken] = useState("");
  const { instance, accounts } = useMsal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (instance && accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };
  
      instance.acquireTokenSilent(request)
        .then((response) => {
          dispatch(setToken({ token: response.accessToken, expiresOn: response.expiresOn }));
          console.log("Access Token:", response.accessToken);
        })
        .catch((error) => {
          console.error("Token acquisition failed", error);
        });
    }
  }, [accounts, instance]);

  useEffect(() => {
    // Ensure loginRedirect is called only if no accounts are found
    //const accounts = instance.getAllAccounts();
  }, []);

  useEffect(() => {
    document.title = config.browser.title || 'Default Title';
    setFavicon(config.browser.favicon || '/favicon.ico');
  }, []);

  return (
    <>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/home" element={<Home />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/redirectLogin" element={<RedirectLoginPage />} />
        </Routes>
    </>
  );
}


export default App;

