export const setFavicon = (faviconPath) => {
    const link = document.querySelector("link[rel~='icon']");
    if (!link) {
      const newLink = document.createElement("link");
      newLink.rel = "icon";
      newLink.href = faviconPath;
      document.getElementsByTagName("head")[0].appendChild(newLink);
    } else {
      link.href = faviconPath;
    }
  };
  