
import React, { useEffect, useState } from "react";
import NavBar from './components/NavBar.js';
import ChatInput from './components/ChatInput.js';
import PrivateLayout from "../layouts/privateLayout.js";
import HomeContent from "./components/HomeContent.js";
import { useAppDispatch, useAppSelector } from "../store/reducers/store.js";
import ChatContent from "./components/ChatContent.js";
import { setUser } from "../store/actions/chat.js";
import config from "../config.json"
import { useMsal } from "@azure/msal-react";

function Home() {
  const [showSideBarBlock, setSideBarBloc] = useState(config.features.newChat);
  const history_length = useAppSelector(state => state.chat.history.length);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const user = instance.getActiveAccount();
    if (user){
      dispatch(setUser(user));
    }
  }, [instance]);

  const OpenSideBar = () => {
    setSideBarBloc(true);
  };

  const CloseSideBar = () => {
    setSideBarBloc(false);
  };

  return (
    <PrivateLayout>
      <div id="main" style={{ marginLeft: showSideBarBlock ? '255px' : '0px' }} >
        <NavBar displaySide={showSideBarBlock} onDisplaySideOpen={OpenSideBar} onDisplaySideClose={CloseSideBar} />     
        <div className="chatBody">
          {history_length === 0 ?
            <HomeContent />
            :
            <ChatContent />
          }
          
          <ChatInput />
        </div>
      </div>
    </PrivateLayout>
  );
}

export default Home;
