import React, { useState, useEffect } from "react";

import logout from '../.././assets/images/logout.svg';
import loading from '../.././assets/images/Loading.gif';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { useAppDispatch, useAppSelector } from "../../store/reducers/store";
import { loadUserChats, newChat, saveChat } from "../../store/actions/chat";
import SideBarItem from "./SideBarItem";
import config from "../../config.json"
let sideClose;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
  sideClose = require('../.././assets/images/sideclose_dark.svg').default;
} else {
  sideClose = require('../.././assets/images/side_close.svg').default;
}
const SideBar = ({ displaySide, onDisplaySideChange }) => {

    const getConversations = useAppSelector(state => state.chat.chatConversations);
    const chatConversations = React.useMemo(() => {
        if (getConversations) {
          return getConversations.slice().sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
        }
        return getConversations;
      }, [getConversations]);

    const history = useAppSelector(state => state.chat.history);
    const selectedConversation = useAppSelector(state => state.chat.selectedConversation);
    const conversationToSelect = getConversations ? getConversations[selectedConversation] : undefined;

    const [groupedConversations, setGroupedConversations] = useState({});
    const dispatch = useAppDispatch();

    const handleCloseClick = () => {
        onDisplaySideChange();
    };

    const { instance } = useMsal();
    const handleLogoutClick = () => {
        instance.logoutRedirect()
            .then(function (response) { window.location.href = "/" })
            .catch((error) => console.error(error))
    }

    useEffect(() => {
        if (chatConversations === undefined) {
            dispatch(loadUserChats(instance.getActiveAccount()));
        }
    }, [chatConversations, dispatch])

    const onSave = (e) => {
        e.stopPropagation();
        dispatch(saveChat({ account: instance.getActiveAccount(), chatHistory: history }));
    }

    const onNewChat = () => {
        dispatch(newChat({account: instance.getActiveAccount()}));
    }

    useEffect(() => {
        if (chatConversations) {
          
          const calculateTimeIntervals = () => {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            // Calculate other intervals (Previous 7 days, etc.)
    
            const groupedData = {};
            chatConversations.forEach(conversation => {
              const conversationDate = new Date(conversation.Timestamp);
    
              // Determine the time interval based on conversationDate
              const interval = determineTimeInterval(conversationDate);
    
              if (!groupedData[interval]) {
                groupedData[interval] = [];
              }
              groupedData[interval].push(conversation);
            });
    
            setGroupedConversations(groupedData);
          };
    
          calculateTimeIntervals();
        }
      }, [chatConversations]);

    const determineTimeInterval = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);
      
        // Assuming date is a Date object
        const dateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        dateStartOfDay.setHours(0, 0, 0, 0);
        
        if (dateStartOfDay.getTime() === today.getTime()) {
          return 'Today';
        } else if (dateStartOfDay.getTime() === yesterday.getTime()) {
          return 'Yesterday';
        } else if (dateStartOfDay >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)) {
          return 'Previous 7 days';
        } else if (dateStartOfDay >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)) {
          return 'Previous 30 days';
        } else {
          // Handle other intervals like months or years
          const month = date.getMonth() + 1; // Months are zero-indexed
          const year = date.getFullYear();
          return `${month}/${year}`;
        }
      };

      

    return (
        <div>
            {displaySide && (
                <div id="sideBarBlock" className="sidebar">
                    <div className="sideBarContent">
                        <a href="#" id="side_close" onClick={handleCloseClick}>
                            <img src={sideClose} />
                        </a>
                        <div className="newChat">
                            <a href="javascript:;" id="side_newChat" onClick={onNewChat}> New Chat</a>
                        </div>
                        <div className="recentChats">
                            <ul>
                           
                            {selectedConversation === undefined && <li className="unsaved">New conversation {history.length > 0 && !config.features.autosave && <a href="javascript:;" onClick={onSave}>Save chat</a>}</li>}
                                {chatConversations === undefined ?
                                   <>Loading...</>
                                    :
                                    <>
                                    {Object.entries(groupedConversations).map(([interval, conversations]) => (
                                        <div key={interval}>
                                        <h3>{interval}</h3>
                                        <ul>
                                            {conversations.map((conversation, index) => (
                                            <SideBarItem
                                                key={`${interval}-${index}`} // Ensures unique keys
                                                item={conversation}
                                                selected={conversation.ConversationID === conversationToSelect?.ConversationID}
                                                index={index}
                                                onSave={onSave}
                                            />
                                            ))}
                                        </ul>
                                        </div>
                                    ))}
                                    
                                    </>

                                }
                            </ul>
                        </div>
                        {/* <div className="logOut">
                            <Link to="javascript:;" onClick={handleLogoutClick}> <img src={logout} />  Logout</Link>
                        </div> */}
                    </div>

                </div>
            )}
        </div>

    );
}

export default SideBar;
