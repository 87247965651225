import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../store/reducers/store'
import { deleteConversation, renameConversation, selectConversation } from '../../store/actions/chat';
import config from "../../config.json"
import { useMsal } from '@azure/msal-react';
let editIcon; let deleteIcon;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
    editIcon = require('../.././assets/images/edit_dark.svg').default;
    deleteIcon = require('../.././assets/images/delete_dark.svg').default;
} else {
    editIcon = require('../.././assets/images/edit.svg').default;
    deleteIcon = require('../.././assets/images/delete.svg').default;
}

const SideBarItem = ({item, selected, index, onSave}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(item.ConversationTitle);
    const {instance} = useMsal();
    const dispatch = useAppDispatch();
    let itemName = item.ConversationTitle;
    useEffect(() => {
        if (isEditing && !selected){
            onCancel();
        }
    }, [selected])
    const onClick = () => {
        dispatch(selectConversation({conversationID: item.ConversationID, index: index, account: instance.getActiveAccount()}));
    }

    const onDelete = () => {
        dispatch(deleteConversation({conversationID:  item.ConversationID, index: index}))
    }

    const onEdit = () => {
        setIsEditing(true);
    }

    const onNameChanged = (e) => {
        setEditName(e.currentTarget.value);
        
    }
    const onSaveRename = () => {
        dispatch(renameConversation({conversationID:  item.ConversationID, index: index, title: editName}));
        itemName = editName;
        setIsEditing(false);
    }
    const onCancel = () =>{
        setEditName(itemName);
        setIsEditing(false);
    }   
    if (isEditing){
        return <li className={`chat`}><input type='text' value={editName} onChange={onNameChanged} /><a href="javascript:;" onClick={onCancel}>Cancel</a><a href="javascript:;" onClick={onSaveRename}>Save</a> </li>
    }
    return <li className={`chat ${selected ? 'chat-selected' : ''}`} onClick={onClick}><span> {editName} </span> {selected && <>{!config.features.autosave && <a href="javascript:;" onClick={onSave}>Save</a>} <a href="javascript:;" onClick={onDelete}> <img src={deleteIcon} alt="Delete" /></a> <a href="javascript:;" onClick={onEdit}><img src={editIcon} alt="Rename" /></a></>}</li>
}

export default SideBarItem;