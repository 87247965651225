import React, { useRef, useState } from 'react';
import NavBar from './components/NavBar.js';
import ChatInput from './components/ChatInput.js';
import edit_chat from '.././assets/images/edit_chat.svg';
import cognition from '.././assets/images/cognition.svg';
import copy from '.././assets/images/copy.svg';
import Klogo from '.././assets/images/K_logo.svg';
import PrivateLayout from '../layouts/privateLayout.js';

import config from "../config.json"

function Chat() {
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);

  const copyText1 = () => {
    if (textRef1.current) {
      navigator.clipboard.writeText(textRef1.current.textContent);
    }
  };

  const copyText2 = () => {
    if (textRef2.current) {
      navigator.clipboard.writeText(textRef2.current.textContent);
    }
  };


  const [toughtBarBlock1Visible, setToughtBar1] = useState(false); // Initial state
  const [toughtBarBlock2Visible, setToughtBar2] = useState(false); // Initial state
  const toughtBarBlock1Ref = useRef(null);
  const toughtBarBlock2Ref = useRef(null);

  const handleToughtBarBlock1Click = () => {
    setToughtBar1(false);
  };

  const handleToughtBarBlock2Click = () => {
    setToughtBar2(false);
  };

  const openToughtBarBlock1Click = () => {
    setToughtBar1(true);
  };

  const openToughtBarBlock2Click = () => {
    setToughtBar2(true);
  };

  const [userActions1Visible, setUserAction1] = useState(false); // Initial state
  const [userActions2Visible, setUserAction2] = useState(false); // Initial state

  const closeUserAction1Click = () => {
    setUserAction1(false);
  };

  const openUserAction1Click = () => {
    setUserAction1(true);
  };

  const closeUserAction2Click = () => {
    setUserAction2(false);
  };

  const openUserAction2Click = () => {
    setUserAction2(true);
  };

  const [showSideBarBlock, setSideBarBloc] = useState(false);

  const OpenSideBar = () => {
    setSideBarBloc(true);
  };

  const CloseSideBar = () => {
    setSideBarBloc(false);
  };


  return (
    <PrivateLayout>
      <div>
        {toughtBarBlock1Visible && (
          <div id="toughtBarBlock1" className="toughtbar" ref={toughtBarBlock1Ref}>
            <div className="sideBarContent">
              <img src={Klogo} />
              <div className="toughtHeader">
                <h1> Tought Process</h1>
                <ul>
                  <li> Model “gpt3.Sturbo”</li>
                  <li> Deployment “Chat” </li>
                </ul>
              </div>
              <div className="contentContainer">
                <div className="content">
                  <h2> Search Type </h2>
                  <p> Standard Search</p>
                  <h2>Generated Questions </h2>
                  <p> Indisponible </p>
                  <h2>Your Question  </h2>
                  <p> What does a club have to consider when defining the location of the 200 purchase category 1 ticket allocation for visiting clubs? </p>
                  <h2>Answer  </h2>
                  <p>
                    When defining the location of the 200 purchase category 1 ticket allocation for visiting clubs, the seats must be in a safe and secure area in no more than two continuous blocks between the 16m lines.
                    The home club must also arrange appropriate security for these spectators.
                    <br /> <br />
                    Additionally, the visiting club may request a similar level/location and quantity of tickets on a reciprocal basis for the return fixture if the ticketing requirements are not met.
                  </p>
                  <h2>References </h2>
                  <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet ante diam. Vestibulum ut condimentum sapien. Proin ante libero, volutpat ac ornare vel, fringilla a nibh. Fusce urna nisl, scelerisque eu accumsan vel, molestie nec quam. Mauris a leo commodo, euismod mi lobortis, imperdiet eros. Curabitur placerat ex in tellus tempor, sed efficitur sapien commodo. Fusce et tortor at massa tristique molestie eget at velit. Vestibulum et facilisis lacus. Donec egestas, eros et sodales pharetra, quam nisi fringilla ex, eu porttitor est nibh faucibus turpis. Pellentesque eu justo efficitur, viverra turpis sed, hendrerit dui. Maecenas ac velit fermentum diam rutrum ullamcorper at a lorem. Pellentesque non fermentum tellus, non maximus lacus. In molestie, dolor ut finibus lacinia, ante ligula lacinia nunc, eu elementum lectus nulla ut orci. Sed placerat aliquet augue. Sed maximus sapien non massa placerat egestas. <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet ante diam. Vestibulum ut condimentum sapien. Proin ante libero, volutpat ac ornare vel, fringilla a nibh. Fusce urna nisl, scelerisque eu accumsan vel, molestie nec quam. Mauris a leo commodo, euismod mi lobortis, imperdiet eros. Curabitur placerat ex in tellus tempor, sed efficitur sapien commodo. Fusce et tortor at massa tristique molestie eget at velit. Vestibulum </p>
                  <a href="#" onClick={handleToughtBarBlock1Click} > Close </a>
                </div>
              </div>

            </div>

          </div>
        )}
      </div>

      <div>
        {toughtBarBlock2Visible && (
          <div id="toughtBarBlock2" className="toughtbar" ref={toughtBarBlock2Ref}>
            <div className="sideBarContent">
              <img src={Klogo} />
              <div className="toughtHeader">
                <h1> Tought Process</h1>
                <ul>
                  <li> Model “gpt3.Sturbo”</li>
                  <li> Deployment “Chat” </li>
                </ul>
              </div>
              <div className="contentContainer">
                <div className="content">
                  <h2> Search Type </h2>
                  <p> Standard Search</p>
                  <h2>Generated Questions </h2>
                  <p> Indisponible </p>
                  <h2>Your Question  </h2>
                  <p> What does a club have to consider when defining the location of the 200 purchase category 1 ticket allocation for visiting clubs? </p>
                  <h2>Answer  </h2>
                  <p>
                    When defining the location of the 200 purchase category 1 ticket allocation for visiting clubs, the seats must be in a safe and secure area in no more than two continuous blocks between the 16m lines.
                    The home club must also arrange appropriate security for these spectators.
                    <br /> <br />
                    Additionally, the visiting club may request a similar level/location and quantity of tickets on a reciprocal basis for the return fixture if the ticketing requirements are not met.
                  </p>
                  <h2>References </h2>
                  <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet ante diam. Vestibulum ut condimentum sapien. Proin ante libero, volutpat ac ornare vel, fringilla a nibh. Fusce urna nisl, scelerisque eu accumsan vel, molestie nec quam. Mauris a leo commodo, euismod mi lobortis, imperdiet eros. Curabitur placerat ex in tellus tempor, sed efficitur sapien commodo. Fusce et tortor at massa tristique molestie eget at velit. Vestibulum et facilisis lacus. Donec egestas, eros et sodales pharetra, quam nisi fringilla ex, eu porttitor est nibh faucibus turpis. Pellentesque eu justo efficitur, viverra turpis sed, hendrerit dui. Maecenas ac velit fermentum diam rutrum ullamcorper at a lorem. Pellentesque non fermentum tellus, non maximus lacus. In molestie, dolor ut finibus lacinia, ante ligula lacinia nunc, eu elementum lectus nulla ut orci. Sed placerat aliquet augue. Sed maximus sapien non massa placerat egestas. <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet ante diam. Vestibulum ut condimentum sapien. Proin ante libero, volutpat ac ornare vel, fringilla a nibh. Fusce urna nisl, scelerisque eu accumsan vel, molestie nec quam. Mauris a leo commodo, euismod mi lobortis, imperdiet eros. Curabitur placerat ex in tellus tempor, sed efficitur sapien commodo. Fusce et tortor at massa tristique molestie eget at velit. Vestibulum </p>
                  <a href="#" id="close_tought" onClick={handleToughtBarBlock2Click} > Close </a>
                </div>

              </div>

            </div>

          </div>
        )}
      </div>


      <div id="main" style={{ marginLeft: showSideBarBlock ? '255px' : '0px' }} >
        <NavBar displaySide={showSideBarBlock} onDisplaySideOpen={OpenSideBar} onDisplaySideClose={CloseSideBar} />
        <div className="chatBody">
          <div id="chatMessage">
            <div id="chats">
              <div className="userChat">
                <a href="#" id="editUserChat1" onClick={openUserAction1Click} >
                  <img className="editchat-image" src={edit_chat} />
                </a>
                <div className="userInput" id="userInput1" style={{ background: userActions1Visible ? '#F4F4F4' : 'white' }}>
                  <p id="userChat1" contentEditable={userActions1Visible}>
                    What does a club have to consider when defining the location of the 200 purchase category
                    1 ticket allocation for visiting clubs?
                  </p>
                  {userActions1Visible && (
                    <div className="user-actions" id="userActions1">
                      <a href="#" onClick={closeUserAction1Click} className="cancelEdit">  {config.homePage.text.editQuestion.cancelEdit}  </a>
                      <a href="#" onClick={closeUserAction1Click} className="saveEdit"> {config.homePage.text.editQuestion.saveEdit}  </a>
                    </div>
                  )}
                </div>
                <span className="userCharacter"> C </span>
              </div>
              <div className="gptChat">
                <p id="gptChat1">
                  <span id="content1" ref={textRef1} >
                    When defining the location of the 200 purchase category 1 ticket allocation for visiting clubs, the seats must be in a safe and secure area in no more than two continuous blocks between the 16m
                  </span>

                  <span className="gpt-actions">
                    <a href="#" className="gpt-icons" onClick={copyText1}  > <img src={copy} alt="Copy Text" />  </a>
                    <a href="#" className="gpt-icons" onClick={openToughtBarBlock1Click} > <img src={cognition} alt="Cognition" />  </a>
                  </span>

                </p>
              </div>
              <div className="userChat">
                <a href="#" id="editUserChat2" onClick={openUserAction2Click}>
                  <img className="editchat-image" src={edit_chat} />
                </a>
                <div className="userInput" id="userInput2" style={{ background: userActions2Visible ? '#F4F4F4' : 'white' }}>
                  <p id="userChat2" contentEditable={userActions2Visible}>
                    What does a club have to consider when defining the location of the 200 purchase category
                    1 ticket allocation for visiting clubs?
                  </p>
                  {userActions2Visible && (
                    <div className="user-actions" id="userActions2">
                      <a href="#" onClick={closeUserAction2Click} className="cancelEdit">  Cancel  </a>
                      <a href="#" onClick={closeUserAction2Click} className="saveEdit"> Save  </a>
                    </div>
                  )}
                </div>
                <span className="userCharacter"> C </span>
              </div>

              <div className="gptChat">
                <p id="gptChat2" className="chatInterruption">
                  <span id="content2" ref={textRef2}>
                    When defining the location of the 200 purchase category 1 ticket allocation for visiting...
                  </span>
                  <span className="gpt-actions">
                    <a href="#" className="gpt-icons" onClick={copyText2}  > <img src={copy} alt="Copy Text" />  </a>
                    <a href="#" className="gpt-icons" onClick={openToughtBarBlock2Click} > <img src={cognition} alt="Cognition" />  </a>
                  </span>

                </p>
              </div>
              <div className="chatSuggestions">
                <ul>
                  <li>  As there any weather alerts or warnings in effect for Berlin today? </li>
                  <li>  How does the current weather in Berlin compare to the average temperture and precipitation for this time of the year? </li>
                </ul>
              </div>
            </div>

          </div>
          <ChatInput />
        </div>
      </div>
    </PrivateLayout>
  );
}

export default Chat;
