import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GPTService } from "../../services/GptService";
import { Utilities } from "../../utils/Utilities";
import config from "../../config.json"
import { store } from "../reducers/store";

export const setUser = createAction('chat/setUser');
export const setToken = createAction('chat/setToken');
export const setHasConsented = createAction('chat/setHasConsented')
export const setGPTVersion = createAction('chat/setGPTVersion');
export const setTemperature = createAction('chat/setTemperature');
export const setSearchTarget = createAction('chat/setSearchTarget');
export const setSearchType = createAction('chat/setSearchType');
export const setQuestion = createAction('chat/setQuestion');
export const addFiles = createAction('chat/addFiles');
export const removeFile = createAction('chat/removeFile');
export const clearFiles = createAction('chat/clearFiles');

export const sendChat = createAsyncThunk('chat/sendChat',
    async (question, thunkAPI) => {
        const service = GPTService;
        return await service.handleChat(question, undefined, (message) => {
            thunkAPI.dispatch(updateChatStream({message}))
        });
    }
)

export const updateChatStream = createAction('chat/updateChatStream');

export const regenerate = createAsyncThunk('chat/regenerate',
    async ({question, index}, thunkAPI) => {
        const service = GPTService;
        return await service.regenerate(question, (message) => {
            thunkAPI.dispatch(updateChatStream({message, index}))
        });
    }
)

export const editChat = createAsyncThunk('chat/editChat',
    async ({ question, index }, thunkAPI) => {
        const service = GPTService;
        return await service.handleChat(question, index, (message) => {
            thunkAPI.dispatch(updateChatStream({message, index}))
        });
    }
)
export const abortChat = createAction('chat/abortChat');

export const loadUserChats = createAsyncThunk('chat/loadUserChats',
    async (account) => {
        if (!config.features.newChat) {
            return;
        }
        const service = GPTService;
        return await service.getUserChats(account.localAccountId);
    }
)

export const saveChat = createAsyncThunk('chat/saveChat',
    async ({ account, chatHistory, noAutoSelect }, thunkAPI) => {
        if (!config.features.newChat) {
            return;
        }
        console.log("Saving chat...")
        const state = thunkAPI.getState();
        const service = GPTService;
        const history = chatHistory !== undefined ? chatHistory : state.chat.history;
        if (history.length === 0){
            return;
        }
        const title = (state.chat.selectedConversation !== undefined ? state.chat.chatConversations[state.chat.selectedConversation].ConversationTitle : (history.length > 0 ? Utilities.limitTextLength(history[0].question, 20) : "New convesation"));
        const savingData =
        {
            UserID: account.localAccountId,
            Messages: history.map(chatEntry => ({
                Timestamp: new Date().toISOString(),
                Question: chatEntry.question,
                Answer: chatEntry.answer,
                AnswerHtml: chatEntry.answer_html,
                AnswerMarkdown: chatEntry.answer_markdown, // Assuming answer contains markdown format
                Temperature: chatEntry.temperature || state.chat.temperature,
                GptModel: chatEntry.gpt_model || state.chat.gptVersion,
                References: chatEntry.references, // Added new parameter
                SuggestedQuestions: chatEntry.suggestedQuestions, // Added new parameter
                ChatHistory: chatEntry.chat_history, // Added new parameter
                QuestionsGenerated: chatEntry.questions_generated // Added new parameter
            })),
            ConversationTitle: title, // Use the provided title
            ConversationID: (state.chat.selectedConversation !== undefined ? state.chat.chatConversations[state.chat.selectedConversation].ConversationID : undefined)
        };
        const result = await (state.chat.selectedConversation !== undefined ? service.updateChat(savingData) : service.addChat(savingData));
        return {
            UserID: account.localAccountId,
            ConversationID: state.chat.selectedConversation !== undefined ? state.chat.chatConversations[state.chat.selectedConversation].ConversationID : result.ConversationID,
            ConversationTitle: title,
            Timestamp: new Date().toISOString(),
            IsNew: state.chat.selectedConversation === undefined
        }
        // thunkAPI.dispatch(loadUserChats(account));
    }
)

export const selectConversation = createAsyncThunk('chat/selectConversation',
    async ({conversationID, index, account}, thunkAPI) => {
        if (!config.features.newChat) {
            return;
        }
        const state = store.getState();
        if (state.chat.fetching){
            console.log("Select conversation while chatting...")
            const history = [...state.chat.history]
            history[history.length - 1] = {...history[history.length - 1]  , answer: "You have interrupted the Answer", answer_html: "<p>You have interrupted the Answer</p>"}
            // await store.dispatch(saveChat({ account: account, chatHistory: history, noAutoSelect: true}))
            store.dispatch(abortChat(false));
        }
        const service = GPTService;
        return await service.getChatHistory(conversationID);
    }
)

export const newChat = createAction('chat/newChat',
    async (payload) => {
        const state = store.getState();
        if (state.chat.fetching){
            console.log("new chat while chatting...")
            const history = [...state.chat.history]
            if(history.length > 0){
                history[history.length - 1] = {...history[history.length - 1]  , answer: "You have interrupted the Answer", answer_html: "<p>You have interrupted the Answer</p>"}
                if(payload !== undefined){
                    await store.dispatch(saveChat({ account: payload.account, chatHistory: history, noAutoSelect: true}))
                }           
            }
            store.dispatch(abortChat(false));
        }
        return { payload: payload}
    }
);

export const deleteConversation = createAsyncThunk('chat/deleteConversation',
    async ({conversationID, index}, thunkAPI) => {
        if (!config.features.newChat) {
            return;
        }
        const service = GPTService;
        return await service.deleteChat(conversationID);
    }
)

export const renameConversation = createAsyncThunk('chat/renameConversation',
    async ({conversationID, index, title}, thunkAPI) => {
        if (!config.features.newChat) {
            return;
        }
        const service = GPTService;
        return await service.renameChat(conversationID, title);
    }
)