import { useEffect } from "react";
import PropTypes from "prop-types";

function PublicLayout({ background, children }) {

  useEffect(() => {
  }, []);

  return (
    <div>
      {children}
    </div>
  );
}

// Setting default values for the props for PublicLayout
PublicLayout.defaultProps = {
  background: "default",
};

// Typechecking props for the PageLayout
PublicLayout.propTypes = {
  background: PropTypes.oneOf(["white", "light", "default"]),
  children: PropTypes.node.isRequired,
};

export default PublicLayout;
