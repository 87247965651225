import React, { useState, useEffect } from "react";
import logo from '.././assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EventType } from '@azure/msal-browser'
import { loginRequest } from "./../appConfig";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../store/reducers/store";
import { setHasConsented, setUser } from "../store/actions/chat";
import { GPTService } from "../services/GptService";

import config from "../config.json"

function Login() {
    const { instance } = useMsal();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let loginFailure = 0;
    const handleLogin = () => {
        //instance.loginRedirect(loginRequest)
        instance.loginPopup(loginRequest)
            .then(function (response) {
                const accounts = instance.getAllAccounts();
                if (accounts.length > 0) {
                    instance.setActiveAccount(accounts[0]);
                    //console.log(accounts);
                }

                instance.addEventCallback(async (event) => {
                    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                        console.log("CALLBACK called")
                        const account = event.payload.account;
                        instance.setActiveAccount(account);
                        dispatch(setUser(account));
                        // const hasConsented = await GPTService.getHasConsented(accounts[0].localAccountId);
                        // if (hasConsented === 'true'){
                        //   dispatch(setHasConsented(true));
                          navigate("/home");
                        // }else{
                        //   navigate("/disclaimer");
                        // }
                        //console.log(account);
                    }
                })
            })
            .catch((error) => {
                console.error(error);
                if (error.message.indexOf('user_cancelled') == -1 && loginFailure < 5) {
                    loginFailure++;
                    handleLogin();
                }
            })
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="login_theme">
            <AuthenticatedTemplate>
                <Navigate to="/home" />
            </AuthenticatedTemplate>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="loginBlock">
                            <div className="blockHeader">
                                <img src={logo} /> {config.homePage.text.appName}
                            </div>
                            <div className="blockTitle">
                                {config.loginPage.text.title}
                            </div>
                            <div className="blockContent">
                            {config.loginPage.text.subTitle}
                            </div>
                            <div className="blockAction">
                                <Link onClick={handleLogin}>{config.loginPage.text.logInButton}</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Login;
