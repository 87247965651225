import { store } from '../store/reducers/store'
import CustomAxios from '../utils/Axios';
import { Constants } from '../utils/Constants';
import { Utilities } from '../utils/Utilities';
import SocketHandler from './SocketHandler';
import config from '../config.json';
import { loginRequest, msalConfig, tokenRequest } from '../appConfig'
import { PublicClientApplication } from "@azure/msal-browser";
import { setToken } from '../store/actions/chat';

const getRemoteUrl = (searchTarget) => {
    return Constants.URI[searchTarget];
}

const socketHandler = SocketHandler;

const isTokenExpired = (state) => {
    return state.chat.tokenExpires === undefined || state.chat.tokenExpires.getTime() < Date.now();
}
// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(msalConfig);

const renewToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
        return undefined;   
    }
    const request = {
        ...loginRequest,
        account: activeAccount || accounts[0]
    };
    await msalInstance.initialize();

    const authResult = await msalInstance.acquireTokenSilent(request).catch((error) => console.log(error));

    return { token: authResult.accessToken, expiresOn: authResult.expiresOn };
}

export const GPTService = {
    abortController: undefined,
    handleChat: async (question, historyLimit, onUpdate) => {
        const state = store.getState();

        if (isTokenExpired(state)){
            const token = await renewToken();
            store.dispatch(setToken(token))
        }

        GPTService.abortController = new AbortController();

        const documents = (state.chat.files.length > 0 ? await Promise.all(state.chat.files.map((file) => Utilities.docToBase64(file))) : undefined);
        const target = (documents !== undefined ? Constants.SearchTarget.Document : state.chat.searchTarget)

        if (config.features.streaming) {
            return await socketHandler.getStreamData(getRemoteUrl(target),
                state.chat.token,
                {
                    question: question,
                    documents: documents,
                    chat_history: (historyLimit !== undefined ? state.chat.history.slice(0, historyLimit) : state.chat.history).filter(h => h.answer !== undefined).map(h => ({ question: h.question, answer: h.answer })),
                    gpt_model: state.chat.gptVersion,
                    temperature: state.chat.temperature / 10,
                    search_type: state.chat.searchType,
                    deployement: target.toLocaleLowerCase(),
                    number_of_documents: state.chat.nbOfDocuments,
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                }, onUpdate);
        } else {
            return CustomAxios.post(
                getRemoteUrl(target),
                {
                    question: question,
                    documents: documents,
                    chat_history: (historyLimit !== undefined ? state.chat.history.slice(0, historyLimit) : state.chat.history).filter(h => h.answer !== undefined).map(h => ({ question: h.question, answer: h.answer })),
                    gpt_model: state.chat.gptVersion,
                    temperature: state.chat.temperature / 10,
                    search_type: state.chat.searchType,
                    deployement: target.toLocaleLowerCase(),
                    number_of_documents: state.chat.nbOfDocuments,
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
                        "Authorization": `Bearer ${state.chat.token}`
                    },
                    signal: GPTService.abortController.signal,
                }
            ).then(r => r.data);
        }
    },
    regenerate: async (question, onUpdate) => {
        const state = store.getState();

        if (isTokenExpired(state)){
            const token = await renewToken();
            store.dispatch(setToken(token))
        }
        
        GPTService.abortController = new AbortController();

        if (config.features.streaming) {
            return await socketHandler.getStreamData(Constants.URI.Regenerate,
                state.chat.token,
                {
                    question: question.question,
                    gpt_model: question.gpt_model,
                    temperature: question.temperature,
                    deployement: "regenerate",
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                    chat_history: [],
                    chunks: question.chunks
                }, onUpdate);
        } else {
            return CustomAxios.post(
                Constants.URI.Regenerate,
                {
                    question: question.question,
                    gpt_model: question.gpt_model,
                    temperature: question.temperature,
                    deployement: "regenerate",
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                    chat_history: [],
                    chunks: question.chunks
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
                        "Authorization": `Bearer ${state.chat.token}`
                    },
                    signal: GPTService.abortController.signal,
                }
            ).then(r => r.data);
        }
    },
    cancelChat: () => {
        socketHandler.cancelStream();
    },
    getHasConsented: async (uuid) => {
        return await CustomAxios.post(Constants.URI.GetConsent, {
            uuid: uuid
        }, {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
            }
        }).then(r => r.data)
    },
    makeConsent: async (uuid, email) => {
        await CustomAxios.post(Constants.URI.CreateConsent, {
            uuid: uuid,
            email: email
        }, {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
            }
        });
    },
    getUserChats: async (uuid) => {
        return await CustomAxios.get(`${Constants.URI.GetUserChats}/${uuid}`).then(r => r.data);
    },
    getChatHistory: async (convId) => {
        return await CustomAxios.get(`${Constants.URI.GetChatHistory}/${convId}`).then(r => r.data);
    },
    addChat: async (data) => {
        return await CustomAxios.post(`${Constants.URI.AddChatHistory}`, data).then(r => r.data);
    },
    updateChat: async (data) => {
        return await CustomAxios.put(`${Constants.URI.UpdateChatHistory}/${data.ConversationID}`, data).then(r => r.data);
    },
    renameChat: async (id, title) => {
        return await CustomAxios.put(`${Constants.URI.RenameChatHistory}/${id}`, { title: title }).then(r => r.data);
    },
    deleteChat: async (id) => {
        await CustomAxios.delete(`${Constants.URI.DeleteChatHistory}/${id}`).then(r => r.data);
    }
}