import { useMsal } from "@azure/msal-react";
import config from "../config.json"

const useIsAdmin = () => {
  const { accounts } = useMsal();
  const userRoles =
    accounts.length > 0 ? accounts[0].idTokenClaims.roles || [] : [];

  const isAdmin = userRoles.includes(config.features.adminOnlyAccess_SharePoint.adminRoleName);

  if (!config.features.adminOnlyAccess_SharePoint.isActive) {
    return true; // If not enforcing, always return true to show all components
  }


  return isAdmin;
};

export default useIsAdmin;
