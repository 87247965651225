import React, { useState , useEffect} from "react";
import close from '../.././assets/images/close_small.svg';
import { useAppDispatch, useAppSelector } from "../../store/reducers/store";
import { clearFiles, removeFile } from "../../store/actions/chat";


function DocumentsNav() {
    const dispatch = useAppDispatch();
    const files = useAppSelector(state => state.chat.files);

    const emptyDocumentList = () =>{
        dispatch(clearFiles());
    }
    const handleDocumentClick = (event, index) => {
        event.preventDefault(); // Prevent default link behavior
        dispatch(removeFile(index))
    };

    // Function to extract file extension (assuming valid filenames)
    function getExtension(fileName) {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : '';
    }

    const removeText = files.length > 1 ? "Remove files" : "Remove file";
    
    return (    
        <> 
        {files.length > 0 && <div className="chatNav" id="docNav">
            <div className="row">
                <div className="col-md-11">
                    <div className="documentsNav">
                    <ul id="documentsListingNav" className="documentsListing">
                    {files.map((document, index) => {
                        // Extract file extension
                        let extension = getExtension(document.name);

                        return (
                        <li key={`file-${index}`} className={extension}>
                            {document.name}
                            <a href="#" onClick={(event) => handleDocumentClick(event, index)}>
                            <img src={close} alt="Close" />
                            </a>
                        </li>
                        );
                    })}
                </ul>
                    </div>
                </div>
                <div className="col-md-1">
                    <div className="documentsActions">
                        <a href="#" onClick={emptyDocumentList}  id="">  {removeText} </a>
                    </div>
                </div>
            </div>
        </div>}
        </>
    );
}

export default DocumentsNav;
