import React, { useEffect, useMemo, useRef, useState } from "react";
import loading from '../.././assets/images/Loading.gif';
import ChatToughtBar from "./ChatToughtBar";
import ChatReferences from "./ChatReferences";
import ChatReferencesBar from "./ChatReferencesBar";
import { useAppDispatch, useAppSelector } from "../../store/reducers/store";
import { editChat, saveChat } from "../../store/actions/chat";
import { useMsal } from "@azure/msal-react";
import config from "../../config.json"

function ChatContentItem({ entry, index, isLast }) {
    const dispatch = useAppDispatch();
    const fetching = useAppSelector(state => state.chat.fetching)
    const textRef1 = useRef(null);

    const [editAction, setEditAction] = useState(false); // Initial state
    const [toughtBarBlockVisible, setToughtBar] = useState(false); // Initial state
    const [referenceBarBlockVisible, setReferenceBar] = useState(false);
    const [editText, setEditText] = useState(entry.question);
    const { instance } = useMsal();
    const [displayText, setDisplayText] = useState(entry.answer_html);

    useEffect(() => {
        setDisplayText(entry.answer_html);
    }, [entry.answer_html])

    const initial = useMemo(() => {
        return instance.getActiveAccount().username.charAt(0).toLocaleUpperCase();
    }, [instance])

    const onCancelEdit = () => {
        setEditText(entry.question);
        setEditAction(false);
    };

    const onSaveEdit= () => {
        if (fetching){
            return;
        }
        setEditAction(false);
        dispatch(editChat({ question: editText, index: index})).then(({ payload }) => {
            if (config.features.autosave && payload !== undefined){
                dispatch(saveChat({account: instance.getActiveAccount(), chatHistory: undefined}));
            }
        }).catch(() => {});
    }

    const onEdit = () => {
        setEditAction(true);
    };

    const onQuestionEdit = (e) => {
        setEditText(e.currentTarget.value);
    }

    const openToughtBarBlockClick = () => {
        setToughtBar(true);
    };
    const closeToughtBarBlockClick = () => {
        setToughtBar(false);
    };

    const openReferenceBarBlockClick = (event) => {
        event.preventDefault();
        setReferenceBar(true);
    };
    const closeReferenceBarBlockClick = () => {
        setReferenceBar(false);
    };

    const copyText1 = () => {
        if (textRef1.current) {
            const range = document.createRange();
            const selection = window.getSelection();          
            // Clear previous selections
            selection.removeAllRanges();          
            // Select the content inside the element
            range.selectNodeContents(textRef1.current);
            selection.addRange(range);           
            // Copy to clipboard
            document.execCommand('copy');    
            // Clear the selection after copying
            selection.removeAllRanges();
        }
    }; 

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            onSaveEdit()
        }
    }
  
    let primaryColor;  let secondaryColor;  let visibility;
    let copy;  let cognition; let edit_chat; let refresh;
    const darkModeStorage = localStorage.getItem('darkMode');
    const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
    const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
    // Dynamically import styles based on dark mode
    if (darkMode) {
        secondaryColor ="#FFFFFF33";
        primaryColor ="#292929";
        copy = require('../.././assets/images/copy_dark.svg').default;
        cognition = require('../.././assets/images/cognition_dark.svg').default;
        edit_chat = require('../.././assets/images/editchat_dark.svg').default;
        visibility =  require('../.././assets/images/visibility_dark.svg').default;
        refresh = require('../.././assets/images/refresh_dark.svg').default;
    } else {
        secondaryColor ="#F4F4F4";
        primaryColor ="White";
        copy = require('../.././assets/images/copy.svg').default;
        cognition = require('../.././assets/images/cognition.svg').default;
        edit_chat = require('../.././assets/images/edit_chat.svg').default;
        visibility =  require('../.././assets/images/visibility.svg').default;
        refresh = require('../.././assets/images/refresh.svg').default;
    }
    
    return <>
        <div className="userChat">
            <a href="#" id="editUserChat1" onClick={onEdit} >
                <img className="editchat-image" src={edit_chat} />
            </a>
            <div className="userInput" id="userInput1" style={{ background: editAction ? secondaryColor : primaryColor }}>
                {editAction ?
                    <input id="userChat1" type="text" value={editText} onChange={onQuestionEdit} onKeyDown={onKeyDown} />
                    :
                    <p id="userChat1">{entry.question}</p>
                }
                {editAction && (
                    <div className="user-actions" id="userActions1">
                        <a href="#" onClick={onCancelEdit} className="cancelEdit"> {config.homePage.text.editQuestion.cancelEdit} </a>
                        <a href="#" onClick={onSaveEdit} className="saveEdit"> {config.homePage.text.editQuestion.saveEdit} </a>
                    </div>
                )}
            </div>
            <span className="userCharacter"> {initial}</span>
        </div>
        <div className="gptChat">
            <p id="gptChat1">
                {displayText === undefined ?
                    <img src={loading} alt="Loading..." />
                    :
                    <>
                        <span id="content1" ref={textRef1} dangerouslySetInnerHTML={{ __html: displayText }}>
                        </span>
                        <span className="gpt-actions" style={{ marginTop: entry.references && entry.references.length > 0 ? '25px' : '0' }}>
                            <ChatReferences entry={entry} openReferences={openReferenceBarBlockClick}/>
                            {!fetching && <a href="#" className="gpt-icons" onClick={copyText1}  > <img src={copy} alt="Copy Text" />  </a>}
                            {!fetching && <a href="#" className="gpt-icons" onClick={openToughtBarBlockClick} > <img src={cognition} alt="Cognition" />  </a>}
                            {entry.references && entry.references.length > 0 && <a href="#" className="gpt-icons" onClick={openReferenceBarBlockClick} > <img src={visibility} alt="References" />  </a>}
                        </span>
                    </>
                }

            </p>
        </div>
        {toughtBarBlockVisible && <ChatToughtBar entry={entry} onClose={closeToughtBarBlockClick} />}
        {referenceBarBlockVisible && <ChatReferencesBar entry={entry} questionIndex={index} isLast={isLast} onClose={closeReferenceBarBlockClick} />}
    </>
}
export default ChatContentItem;