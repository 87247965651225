import { combineReducers, configureStore } from '@reduxjs/toolkit'
// import { persistStore, persistReducer } from 'redux-persist' //TO INSTALL IF NEEDED
// import storage from 'redux-persist/lib/storage' // defaults to localStorage
import chat from './chat'
import { useDispatch, useSelector } from 'react-redux'
import {thunk} from 'redux-thunk'

const rootReducer = combineReducers({
    chat,
})

// const persistedReducer = persistReducer(
//     {
//         key: 'root',
//         storage,
//         whitelist: []
//     },
//     rootReducer
// )

export const store = configureStore({
    reducer: rootReducer, //persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            thunkMiddleware: thunk
        })
})


export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;


// export const persistor = persistStore(store)
